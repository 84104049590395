import { z } from 'zod';

const message = 'Необходимо выбрать один из вариантов';
// eslint-disable-next-line import/prefer-default-export
export const legalEntityReestrImportSchema = z.object({
  month: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), 'Выберите месяц'),
  insuranceRows: z.array(z.object({
    selectedCompanyId: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), message),
    selectedProgramId: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), message),
    selectedSubprogramId: z.object({
      id: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), message),
      name: z.string().nullable().refine((value) => value !== null && value !== '', message),
    }).nullable().refine((value) => value !== null, {
      message,
    }),
  })),
});
